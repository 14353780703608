// 共通初期化関数
const initialize = () => {
  form();
};

// メイン関数
const main = () => {
  document.addEventListener("turbo:load", initialize);
  document.addEventListener("turbo:render", initialize);
};
main();

/**
 * formのUX
 * @returns
 */
function form() {
  const btnShowPass = document.querySelector('.pass-label__show');
  const btnClear = document.querySelectorAll('.clear');

  if (btnShowPass != null && btnClear != null) {
    btnShowPass.addEventListener('click', e => {
      const t = e.target;
      if (t instanceof HTMLElement) {
        const parentNode = t.parentNode;
        if (t.classList.contains('is-show')) {
          t.classList.remove('is-show');
          parentNode.querySelector('.pass-label__password').type = 'password';
        } else {
          t.classList.add('is-show');
          parentNode.querySelector('.pass-label__password').type = 'text';
        }
      }
    });
    btnClear.forEach(btn => {
      btn.addEventListener('click', () => {
        btn.previousElementSibling.value = '';
      });
    });
  }
}
